import { createSelector } from 'reselect';

import { getMenuTime, getShopMenu, getTodayStoreTime } from 'utils/helper';

import { RootState } from '..';

const selectShop = (state: RootState) => state.shop;

export const selectCurrentShop = createSelector(
  [selectShop],
  (shop) => shop.currentShop
);

export const selectShopError = createSelector(
  [selectShop],
  (shop) => shop.errorMessage
);

export const selectShopCurrencySymbol = createSelector(
  [selectShop],
  (shop) => shop.currencySymbol
);

export const selectShopMenu = createSelector(
  [selectShop],
  (shop) => {
    if (shop.currentShop)
      return getShopMenu(shop.currentShop)

    // const shopMenu = shop.currentShop?.store_menu.menus;

    // if (shopMenu) return Object.values(shopMenu)[0];

    // return null;
  });

export const selectFormattedShopMenu = createSelector(
  [selectShop],
  (shop) => {
    const shopMenu = shop.currentShop?.store_menu.menus;

    const categories = shop.currentShop?.store_menu.categories;
    const items = shop.currentShop?.store_menu.items;

    if (shopMenu) {
      const menu = Object.values(shopMenu)[0];

      return menu?.categoryIds.map((categoryId) => {
        const menuItem = categories?.[categoryId];
        const categoryItems = menuItem?.itemIds.map((itemId) => {
          return items?.[itemId];
        });

        return {
          ...menuItem,
          items: categoryItems,
        };
      });
    }

    return null;
  });

export const selectMenuTime = createSelector(
  [selectShopMenu],
  (shopMenu) => {
    if (shopMenu) {
      const time = getTodayStoreTime(shopMenu);

      return getMenuTime(time)
    }

    return null
  });

export const selectDiscountTooltip = createSelector(
  [selectCurrentShop, selectShopCurrencySymbol],
  (shop, currencySymbol) => {

    const fixedDiscount = Number(shop?.store_details?.store_discount_amount);
    const percentagedDiscount = Number(shop?.store_details?.store_discount_percent);

    if (fixedDiscount >= 0) {
      return `Discount of ${currencySymbol}${fixedDiscount} on min bill of ${shop?.store_details?.store_discount_min_bill
        }`;
    } else if (percentagedDiscount) {
      return `Discount of ${percentagedDiscount}% upto max of ${currencySymbol}${shop?.store_details?.store_discount_max_amount
        } on min bill of ${currencySymbol} ${shop?.store_details?.store_discount_min_bill
        }`;
    }

    return null;
  });

// export const selectDiscount = createSelector(
//   [selectCurrentShop],
//   (shop) => {

//     const fixedDiscount = Number(shop?.store_details?.store_discount_amount);
//     const percentagedDiscount = Number(shop?.store_details?.store_discount_percent);

//     if (fixedDiscount >= 0) {
//       return `Discount of ${currencySymbol}${fixedDiscount} on min bill of ${shop?.store_details?.store_discount_min_bill
//         }`;
//     } else if (percentagedDiscount) {
//       return `Discount of ${percentagedDiscount}% upto max of ${currencySymbol}${shop?.store_details?.store_discount_max_amount
//         } on min bill of ${currencySymbol} ${shop?.store_details?.store_discount_min_bill
//         }`;
//     }

//     return null;
//   });