import { createSelector, Selector } from 'reselect';

import { selectCurrentShop } from 'store/shop/shop.selectors';
import { IShopItem } from 'store/shop/shop.types';

import { RootState } from '..';
import { ICartState, ICartItem, EDeliveryMode } from './cart.types';
import { calculateCartSubTotalReducer } from './cart.utils';

const selectCart = (state: RootState): ICartState => state.cart;

export const selectCartItemQuantity = (currentItem: IShopItem) =>
  createSelector(selectCart, (cart) => {

    if (currentItem.modifierGroupIds.length > 0) {
      return cart.cartItems.filter((obj) => obj.id === currentItem.id).length
    }

    return cart.cartItems.find((item: ICartItem) => item.id === currentItem.id)?.quantity ?? 0
  }
  );

export const selectCartItems = createSelector(
  [selectCart],
  (cart) => cart.cartItems
);

export const selectCartItemsCount: Selector<RootState, number> = createSelector(
  [selectCartItems],
  (cartItems: ICartItem[]): number =>
    cartItems.reduce(
      (acc: number, item: ICartItem) => acc + (item.quantity ?? 0),
      0
    )
);

export const selectDeliveryMode = createSelector(
  [selectCart],
  (cart) => cart.deliveryMode
);

export const selectCartDeliveryFee: Selector<RootState, number> = createSelector(
  [selectDeliveryMode, selectCurrentShop],
  (deliveryMode: EDeliveryMode, currentShop): number => {
    return deliveryMode === EDeliveryMode.DELIVERY ? currentShop?.store_details.store_delivery_fee ?? 0 : 0
  }
);

export const selectCartSubTotal: Selector<RootState, number> = createSelector(
  [selectCartItems],
  (cartItems: ICartItem[]): number => {
    return cartItems.reduce(calculateCartSubTotalReducer, 0)
  }
);

export const selectCartDiscount: Selector<RootState, number> = createSelector(
  [selectCartSubTotal, selectCurrentShop],
  (subTotal: number, currentShop): number => {
    const storeDetails = currentShop?.store_details

    if (storeDetails) {
      const fixedDiscount = Number(storeDetails?.store_discount_amount);

      const percentagedDiscount = Number(storeDetails?.store_discount_percent);

      if (subTotal > Number(storeDetails?.store_discount_min_bill)) {
        if (fixedDiscount >= 0) {
          return fixedDiscount;
        } else if (percentagedDiscount) {
          const discountAmount = (subTotal * percentagedDiscount) / 100;

          if (discountAmount < storeDetails?.store_discount_max_amount)
            return discountAmount;
          else return storeDetails?.store_discount_max_amount;
        }
      } else return 0;
    }

    return 0;
  }
);

export const selectCartTax: Selector<RootState, number> = createSelector(
  [selectCartDeliveryFee, selectCartSubTotal, selectCartDiscount, selectCurrentShop],
  (deliveryFee: number, subTotal: number, discount: number, currentShop): number => {

    const taxRate = currentShop?.store_details.store_tax_rate ?? 0

    let taxAmount =
      (Number(subTotal) - Number(discount) + Number(deliveryFee)) *
      (Number(taxRate) / 100);
    taxAmount = Number(taxAmount.toFixed(2));

    return taxAmount
  }
);

export const selectCartTotal: Selector<RootState, number> = createSelector(
  [selectCartDeliveryFee, selectCartSubTotal, selectCartDiscount, selectCartTax],
  (deliveryFee: number, subTotal: number, discount: number, tax: number): number => {
    // subTotal - discount + deliveryFee + tax

    let total =
      Number(subTotal) -
      Number(discount) +
      Number(deliveryFee) +
      Number(tax);
    total = Number(total.toFixed(2));

    return total
  }
);

