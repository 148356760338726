import moment from 'moment';

import { IMenu, IInterval, IShope } from 'store/shop/shop.types';

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query?.split('&') || [];

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');

    if (pair[0] == variable) {
      return pair[1];
    }
  }

  return false;
}

// export function groupBy(key) {
//   return function group(array) {
//     return array.reduce((acc, obj) => {
//       const property = obj[key];
//       acc[property] = acc[property] || [];
//       acc[property].push(obj);
//       return acc;
//     }, {});
//   };
// }

// export const isObjEmpty = (obj) => {
//   const emptyObj = !!(
//     obj && // 👈 null and undefined check
//     Object.keys(obj).length === 0 &&
//     Object.getPrototypeOf(obj) === Object.prototype
//   );
//   return emptyObj;
// };

export function calcTime(offset: string) {
  if (!offset) return null

  // return moment().utcOffset('+05:30').format('YYYY-MM-DD HH:mm')
  return moment().utcOffset(offset).format('HH:mm');
}

export const compareTime = (time: IInterval, localTimeWithZone: any) => {
  const localTime = localTimeWithZone.split(':');
  const startTime = moment({
    h: time.fromHour,
    m: time.fromMinute,
  });

  const endTime = moment({
    h: time.toHour,
    m: time.toMinute,
  });

  // console.log(startTime.isBefore(endTime)); //true
  // console.log(moment({
  //   h: localTime[0],
  //   m: localTime[1],
  // }).isBetween(startTime, endTime))
  return moment({
    h: localTime[0],
    m: localTime[1],
  }).isBetween(startTime, endTime)
};

export const getShopMenu = (currentShop: IShope) => {
  const shopMenu = currentShop?.store_menu.menus;

  if (shopMenu) return Object.values(shopMenu)[0];
};

export function getTodayStoreTime(shopMenu: IMenu) {
  const DAYS = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
  ];
  const d = new Date();
  const day = DAYS[d.getDay()];
  const intervals = shopMenu?.hours.intervals;

  return intervals.filter((e) => e.day == day)[0];

  // if (intervals) {
  //   const matchedDay = intervals.filter((e) => e.day == day)[0];

  //   return `Menu Hours: ${matchedDay.fromHour}:${(
  //     '0' + matchedDay.fromMinute
  //   ).slice(-2)} to ${matchedDay.toHour}:${(
  //     '0' + matchedDay.toMinute
  //   ).slice(-2)}`
  // }

  // return null;
}

export const getMenuTime = (time: IInterval) => {

  return `Menu Hours: ${time?.fromHour}:${(
    '0' + time?.fromMinute
  ).slice(-2)} to ${time?.toHour}:${(
    '0' + time?.toMinute
  ).slice(-2)}`
}

// Google Address
