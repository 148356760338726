import { Dispatch } from 'redux';

import { ECartActionTypes, ICartItem, TCartActions, EDeliveryMode } from './cart.types';

export const addSimpleItemToCart = (item: ICartItem) => {
  return (dispatch: Dispatch<TCartActions>) => {
    dispatch({
      type: ECartActionTypes.ADD_SIMPLE_ITEM,
      payload: item,
    });
  };
};

export const addComplexItemToCart = (item: ICartItem) => {
  return (dispatch: Dispatch<TCartActions>) => {
    dispatch({
      type: ECartActionTypes.ADD_COMPLEX_ITEM,
      payload: item,
    });
  };
};

export const clearItemFromCart = (item: ICartItem) => {
  return (dispatch: Dispatch<TCartActions>) => {
    dispatch({
      type: ECartActionTypes.CLEAR_ITEM_FROM_CART,
      payload: item,
    });
  };
};

export const removeItemFromCart = (item: ICartItem) => {
  return (dispatch: Dispatch<TCartActions>) => {
    dispatch({
      type: ECartActionTypes.REMOVE_ITEM,
      payload: item,
    });
  };
};

export const clearCart = () => {
  return (dispatch: Dispatch<TCartActions>) => {
    dispatch({
      type: ECartActionTypes.CLEAR_CART,
    });
  };
};

export const toggleDeliveryMode = (deliveryMode: EDeliveryMode) => {
  return (dispatch: Dispatch<TCartActions>) => {
    dispatch({
      type: ECartActionTypes.TOGGLE_DELIVERY_MODE,
      payload: deliveryMode,
    });
  };
};
