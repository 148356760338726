/* eslint-disable sonarjs/no-duplicate-string */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { useEffect } from 'react';

import Autocomplete from 'react-google-autocomplete';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PhoneNumber } from 'components';
import { config } from 'config';
import { selectDeliveryMode } from 'store/cart/cart.selectors';
import { EDeliveryMode } from 'store/cart/cart.types';
import { selectCurrentShop } from 'store/shop/shop.selectors';
import { setCurrentUserAddress } from 'store/user/user.action.creator';
import { IAddress, IAddAddress, TAddressTag } from 'store/user/user.types';

import './AddressForm.css';

interface IFormInputs {
  name: string;
  phone: string;
  address1: string;
  address2: string;
  landmark: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  tag: TAddressTag;
}
interface Props { }

export const AddressForm = () => {
  const navigate = useNavigate();
  const { search, } = useLocation();
  const currentShop = useSelector(selectCurrentShop);
  const dispatch = useDispatch();
  const country = currentShop?.store_details.store_country;
  const countryCode =
    currentShop?.store_details.store_country_code.toLocaleLowerCase() ?? '';
  const deliveryMode = useSelector(selectDeliveryMode);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (deliveryMode === EDeliveryMode.PICKUP) {
      // navigate(`/processToPay${search}`);
      goBack()
    }
  }, [deliveryMode, navigate, search]);

  const defaultValues = {
    name: '',
    phone: '',
    address1: '',
    address2: '',
    landmark: '',
    city: '',
    state: '',
    country: country,
    pinCode: '',
    tag: 'Other' as TAddressTag,

    // name: 'name',
    // phone: '1209123',
    // address1: 'ad1',
    // address2: 'ad2',
    // landmark: 'landmark',
    // city: 'lhr',
    // state: 'state',
    // country: 'IN',
    // pinCode: '1231',
    // tag: 'Home',
  };

  const {
    watch,
    reset,
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, },
  } = useForm<IFormInputs>({
    mode: 'onTouched',
    // mode: 'onBlur', //? The blur event fires when an element has lost focus
    // mode: 'onSubmit', //? The blur event fires when an element has lost focus
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const { tag, ...address } = data;

    const addedAddress: IAddress = {
      ...address,
      selected: true,
    };

    const saveAddress: IAddAddress = {
      [tag]: addedAddress,
    };

    dispatch(setCurrentUserAddress(saveAddress));
    goBack();
  };

  return (
    <>
      <div className='address-form'>
        <div className='heading'>
          <h5>Add Delivery Address</h5>

          <button
            onClick={() => {
              goBack();
            }}
          >
            Go Back
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group mb-3 input-group input-group-lg'>
            <input
              type='text'
              placeholder='Name*'
              {...register('name', { required: true, })}
              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            />

            <div className='invalid-feedback'>{errors.name?.message}</div>
          </div>

          <div
            className='form-group mb-3 input-group input-group-lg'
            style={{
              zIndex: 5,
              position: 'relative',
            }}
          >
            <Controller
              control={control}
              //    defaultValue={phone}
              name={'phone'}
              rules={{ required: true, }}
              render={({ field: { value, onChange, onBlur, }, }) => (
                <>
                  <PhoneNumber
                    // inputClass={'form-control'}
                    containerClass={`form-control ${errors.phone ? 'is-invalid' : ''
                      }`}
                    inputStyle={{
                      minWidth: '100%',
                      maxWidth: '100%',
                      backgroundColor: 'transparent',
                      border: '0px',
                      outline: 'none',
                      WebkitBoxShadow: 'none',
                      MozBoxShadow: 'none',
                      boxShadow: 'none',
                      color: 'transparent',
                      cursor: 'default',
                    }}
                    buttonStyle={{
                      backgroundColor: 'transparent',
                      border: '0px',
                      outline: 'none',
                    }}
                    value={value}
                    onChange={onChange}
                    // country={'in'}
                    country={countryCode}
                    // onlyCountries={['in']}
                    countryCodeEditable={false}
                  // enableSearch
                  />
                </>
              )}
            />

            {/* <input
              placeholder='Phone Number*'
              {...register('phone', { required: true, })}
              type='text'
              maxLength={10}
              pattern='\d{10}'
              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
            />

            <div className='invalid-feedback'>{errors.phone?.message}</div> */}
          </div>

          <div className='form-group mb-3 input-group input-group-lg'>
            {/* <input
              type='text'
              {...register('address1')}
              className={`form-control ${errors.address1 ? 'is-invalid' : ''}`}
            /> */}

            <Controller
              control={control}
              name='address2'
              rules={{ required: true, }}
              render={({
                field: { onChange, onBlur, value, name, ref, },
                fieldState: { invalid, isTouched, isDirty, error, },
                formState,
              }) => (
                <Autocomplete
                  name='address2'
                  // style={{
                  //   width: '100%',
                  //   padding: '0.5 rem 1 rem',
                  //   fontSize: '1.25rem',
                  //   borderRadius: '0.3 rem',
                  // }}
                  className={`form-control ${errors.address2 ? 'is-invalid' : ''
                    }`}
                  placeholder='Delivery to*'
                  apiKey={config.googleAddressKey}
                  onPlaceSelected={(selectedAddress: any) => {
                    const addressNameFormat = {
                      street_number: 'short_name',
                      route: 'long_name',
                      locality: 'long_name',
                      administrative_area_level_1: 'short_name',
                      country: 'long_name',
                      postal_code: 'short_name',
                    };

                    type TAddressType = keyof typeof addressNameFormat;

                    const getAddressComp = function (
                      addressType: TAddressType
                    ) {
                      for (const component of selectedAddress.address_components) {
                        if (component.types[0] === addressType) {
                          return component[addressNameFormat[addressType]];
                        }
                      }

                      return '';
                    };
                    const locationData: {
                      address: string;
                      administrative_area_level_1: string;
                      country: string;
                      locality: string;
                      postal_code: string;
                    } = {
                      address:
                        getAddressComp('street_number') +
                        ' ' +
                        getAddressComp('route'),
                      administrative_area_level_1: '',
                      country: '',
                      locality: '',
                      postal_code: '',
                    };

                    const componentForm = [
                      'location',
                      'locality',
                      'administrative_area_level_1',
                      'country',
                      'postal_code'
                    ];

                    for (const component of componentForm) {
                      // Location field is handled separately above as it has different logic.
                      if (component !== 'location') {
                        locationData[component as keyof typeof locationData] =
                          getAddressComp(component as TAddressType);
                      }
                    }

                    setValue('address2', locationData.address, {
                      shouldValidate: true,
                    });

                    setValue(
                      'state',
                      locationData.administrative_area_level_1,
                      { shouldValidate: true, }
                    );

                    setValue('city', locationData.locality, {
                      shouldValidate: true,
                    });

                    setValue('pinCode', locationData.postal_code, {
                      shouldValidate: true,
                    });

                    setValue('country', locationData.country, {
                      shouldValidate: true,
                    });
                  }}
                  options={{
                    fields: ['address_components', 'geometry', 'name'],
                    types: ['address'],
                    componentRestrictions: { country: countryCode, },
                  }}
                  onBlur={onBlur} // notify when input is touched
                  onChange={onChange} // send value to hook form
                  value={value}
                />
              )}
            />

            <div className='invalid-feedback'>{errors.address2?.message}</div>
          </div>

          <div className='form-group mb-3 input-group input-group-lg'>
            <input
              type='text'
              placeholder='Apartment, unit, suite, or floor #'
              {...register('address1', { required: false, })}
              className={`form-control ${errors.address1 ? 'is-invalid' : ''}`}
            />

            <div className='invalid-feedback'>{errors.address2?.message}</div>
          </div>

          <div className='form-group mb-3 input-group input-group-lg'>
            <input
              type='text'
              placeholder='City*'
              {...register('city', { required: true, })}
              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
            />

            <div className='invalid-feedback'>{errors.city?.message}</div>
          </div>

          <div className='row'>
            <div className='col'>
              <div className='form-group mb-3 input-group input-group-lg'>
                <input
                  type='text'
                  placeholder='State*'
                  {...register('state', { required: true, })}
                  className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                />

                <div className='invalid-feedback'>{errors.state?.message}</div>
              </div>
            </div>

            <div className='col'>
              <div className='form-group mb-3 input-group input-group-lg'>
                <input
                  type='number'
                  placeholder='Pincode*'
                  {...register('pinCode', { required: true, })}
                  className={`form-control ${errors.pinCode ? 'is-invalid' : ''
                    }`}
                />

                <div className='invalid-feedback'>
                  {errors.pinCode?.message}
                </div>
              </div>
            </div>
          </div>

          <div className='form-group mb-3 input-group input-group-lg'>
            <input
              type='text'
              placeholder='Country*'
              {...register('country', { required: true, })}
              // defaultValue={store_details.store_country}
              disabled
              className={`form-control ${errors.country ? 'is-invalid' : ''}`}
            />

            <div className='invalid-feedback'>{errors.country?.message}</div>
          </div>

          <div className='form-group mb-5 input-group input-group-lg selectTag'>
            <p>TAG</p>

            <button
              type='button'
              onClick={() => setValue('tag', 'Home')}
            >
              {' '}
              Home
            </button>

            <button
              type='button'
              onClick={() => setValue('tag', 'Office')}
            >
              {' '}
              Office
            </button>

            <button
              type='button'
              onClick={() => setValue('tag', 'Other')}
            >
              {' '}
              Other
            </button>
          </div>

          <div className='AddAddressBtn'>
            <button type='submit'>Add Address</button>
          </div>
        </form>
      </div>
    </>
  );
};
