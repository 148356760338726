/* eslint-disable sonarjs/no-duplicate-string */
import { Reducer } from 'redux';

import { parsedQueryString } from 'utils/parsedQueryString'

import {
  ICartState,
  ICartItem,
  ECartActionTypes,
  TCartActions,
  EDeliveryMode
} from './cart.types';
import { addSimpleItemToCart, addComplexItemToCart, removeItemFromCart } from './cart.utils';

const cartItems = [
  {
    'id': 'be3286de-c4fb-48b4-9ef0-acac0403df30',
    'name': 'Triple Berry Blast [ Calories 163 | Protein 4 | Carbs 34g](350 ml)',
    'quantity': 1,
    'note': '',
    'price': 229,
    'modifiers': [
      {
        'groupId': 'cee074c0-c73b-4fff-a41d-32c7c6d4a7eb',
        'groupName': 'Base',
        'id': '9214b18c-eeab-4778-9bb1-f43e5e864a55',
        'name': 'Coconut Water',
        'price': 80,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '63944a32-b0ea-4864-b189-7c6f4ddf1342',
        'groupName': 'Add Ons',
        'id': '4580d951-8ac1-458e-9ce0-2fce266d1a56',
        'name': 'Jaggery Powder',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      }
    ],
  },
  {
    'id': '80929a70-e3f4-4e69-9ccd-eb799f5dff9e',
    'name': 'Make Your Own Smoothie',
    'quantity': 1,
    'note': '',
    'price': 169,
    'modifiers': [
      {
        'groupId': '4065a9f9-0ae4-447f-9dff-39dd5eea1df2',
        'groupName': 'Choose Your Base',
        'id': 'f0ac23f6-4f26-476e-ae3f-457ad68882a4',
        'name': 'Water',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'groupId': '6323020f-173d-4169-960a-815ab85f97b2',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'groupName': 'Choose Fruits & Berries ( Choose 2 )',
        'id': '46d28a0e-e221-4381-86fc-9ce5bfc070dd',
        'name': 'Pineapple',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '6323020f-173d-4169-960a-815ab85f97b2',
        'groupName': 'Choose Fruits & Berries ( Choose 2 )',
        'id': '18f1e4b1-717f-46e5-9f09-a43a77f04b73',
        'name': 'Blueberry',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '6323020f-173d-4169-960a-815ab85f97b2',
        'groupName': 'Choose Fruits & Berries ( Choose 2 )',
        'id': 'cf70cca9-d5f8-47aa-bfb3-a8071eedb455',
        'name': 'Banana',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '6323020f-173d-4169-960a-815ab85f97b2',
        'groupName': 'Choose Fruits & Berries ( Choose 2 )',
        'id': '3b00083f-0391-4cb0-860c-effebb1c3fbb',
        'name': 'Apple',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '6323020f-173d-4169-960a-815ab85f97b2',
        'groupName': 'Choose Fruits & Berries ( Choose 2 )',
        'id': 'cd061bbe-1656-45bd-b690-2f346d56b1ee',
        'name': 'Strawberry',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '493ef047-444e-411d-9b0e-9f6f773a791e',
        'groupName': 'Veggies',
        'id': 'c5877eca-84ec-407a-9b97-10c910119deb',
        'name': 'Spinach',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '493ef047-444e-411d-9b0e-9f6f773a791e',
        'groupName': 'Veggies',
        'id': '31c188b5-fccd-4dce-89e4-7de01749c0be',
        'name': 'Carrot',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '493ef047-444e-411d-9b0e-9f6f773a791e',
        'groupName': 'Veggies',
        'id': '3b44c3c6-89d6-476e-8b0e-a98197298a76',
        'name': 'Cucumber',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'eed2e16f-2b53-41e4-9a98-1e1bc7e39480',
        'groupName': 'Seeds',
        'id': '69a93ce3-e687-46ce-840e-3a89a93a2ac5',
        'name': 'Sunflower Seeds',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'eed2e16f-2b53-41e4-9a98-1e1bc7e39480',
        'groupName': 'Seeds',
        'id': 'ad90da8b-387e-4e0c-ada0-0c486d3fce21',
        'name': 'Pumpkin Seeds',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'eed2e16f-2b53-41e4-9a98-1e1bc7e39480',
        'groupName': 'Seeds',
        'id': '9f040723-c337-4144-85a0-8f519c9ec29f',
        'name': 'Flax Seeds',
        'price': 0,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': '5c912d6e-44ce-49ec-aad4-45a4f9323a30',
        'groupName': 'Butter',
        'id': 'b149a5f2-03ba-4545-bce5-b5461c3f26ca',
        'name': 'Almond Butter (Unsweetened)',
        'price': 40,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'c2324ea4-a27d-4e3b-be2b-adee5f3c0cbb',
        'groupName': 'Nuts & Immunity Booster',
        'id': '787a58e7-96db-4d9f-a854-503d6ed46e9a',
        'name': 'Cashews',
        'price': 30,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'c2324ea4-a27d-4e3b-be2b-adee5f3c0cbb',
        'groupName': 'Nuts & Immunity Booster',
        'id': 'fcec3ae3-b14a-485e-a06b-11f4c3b92b74',
        'name': 'Matcha Powder',
        'price': 40,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'c2324ea4-a27d-4e3b-be2b-adee5f3c0cbb',
        'groupName': 'Nuts & Immunity Booster',
        'id': '56801714-0cf3-463d-b076-53609fe2d206',
        'name': 'Wheat Grass Powder',
        'price': 20,
        'quantity': 1,
        'modifiers': [],
      },
      {
        'groupId': 'ac92f432-6fb2-4096-8359-54b052f9fc5b',
        'groupName': 'Protein',
        'id': 'a6103048-cc91-4c24-a680-f519b66ab637',
        'name': 'Whey Protein ( Unsweetened )',
        'price': 60,
        'quantity': 1,
        'modifiers': [],
      }
    ],
  },
  {
    'description': 'This fresh earthy and buttery avocado blend is a perfect doze of nutrition improves gut health, improves vision, improves heart health',
    'id': '7417b699-0c35-441b-bbaa-32c337a550a8',
    'modifiers': [],
    'name': 'Avocado Yoghurt Smoothie 350ml [ Calories 198 | Protein 5g | Carbs 30g]',
    'price': 189,
    'note': '',
    'quantity': 5,
  },
  {
    'description': 'Relish this classic combination of coffee and almonds blended with delicious probiotic yoghurt improves gut health, boosts immunity, improves brain health',
    'id': 'b325720d-1da3-4001-9e96-81ecf124d816',
    'modifiers': [],
    'name': 'Coffee-Almond Yoghurt Smoothie 350 Ml [ Calories 208 | Protein 9g | Carbs 32g]',
    'price': 209,
    'note': '',
    'quantity': 5,
  },
  {
    'description': 'What is better than the king of antioxidant blended with your favourite probiotic yoghurt improves gut health, lowers cholesterol, prevents heart disease',
    'id': 'ec4dfe0a-0acd-45ab-a720-92e1a5109488',
    'modifiers': [],
    'name': 'Blueberry Yoghurt Smoothie 350ml [ Calories 179 | Protein 5g | Carbs 35g]',
    'price': 269,
    'note': '',
    'quantity': 4,
  },
  {
    'description': 'Delicious sweet and tart combination of fresh raspberries and organic cacao powder improves gut health, lowers blood pressure, reduces risk of diabetes',
    'id': '8ea3c803-365d-4259-9787-e4a47c313629',
    'modifiers': [],
    'name': 'Raspberry-Cacao Yoghurt Smoothie 350ml [ Calories 225 | Protein 9g | Carbs 39g]',
    'price': 299,
    'note': '',
    'quantity': 4,
  }
]

let deliveryMode = EDeliveryMode.DELIVERY;

if (parsedQueryString.fulfillment_mode && parsedQueryString.fulfillment_mode.toLocaleUpperCase() in EDeliveryMode) {
  deliveryMode = parsedQueryString.fulfillment_mode.toLocaleUpperCase() as EDeliveryMode;
}
else {
  deliveryMode = EDeliveryMode.DELIVERY;
}

if (parsedQueryString.table_number) {
  deliveryMode = EDeliveryMode.PICKUP
}

const INITIAL_STATE: ICartState = {
  cartItems: [],
  // cartItems,
  // deliveryMode: EDeliveryMode.DELIVERY,
  deliveryMode,
};

export const cartReducer: Reducer<ICartState, TCartActions> = (
  state = INITIAL_STATE,
  action
): ICartState => {
  switch (action.type) {

    case ECartActionTypes.ADD_SIMPLE_ITEM:
      return {
        ...state,
        cartItems: addSimpleItemToCart(state.cartItems, action.payload),
      };

    case ECartActionTypes.ADD_COMPLEX_ITEM:
      return {
        ...state,
        cartItems: addComplexItemToCart(state.cartItems, action.payload),
      };

    case ECartActionTypes.CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item: ICartItem) => item.id !== action.payload.id
        ),
      };

    case ECartActionTypes.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload),
      };

    case ECartActionTypes.CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };

    case ECartActionTypes.TOGGLE_DELIVERY_MODE:
      return {
        ...state,
        deliveryMode: action.payload,
      };

    default:
      return state;
  }
};
