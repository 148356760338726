import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export const getApiUrl = (_url: string) => baseUrl + _url;

async function sendRequest(
  url: string,
  method = 'GET' as const,
  data = null,
  headers = {}
) {
  return axios({
    url,
    method,
    data,
    headers,
    timeout: 5000,
  });
}

export const getProductsList = async (brandId: string) => {
  const url = getApiUrl(`/_fabric/_system/_api/document/store-data/${brandId}`);

  return sendRequest(url, 'GET', null, {
    Authorization: process.env.REACT_APP_BACKEND_AUTH_TOKEN,
  });
};
