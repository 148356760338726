import React from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import deliveryMan from 'assets/images/delivery.png';
import discount1 from 'assets/images/discount1.png';
import stopwatch from 'assets/images/stopwatch1.png';
import { DiscountTooltip } from 'components';
import { toggleDeliveryMode } from 'store/cart/cart.action.creator';
import { selectDeliveryMode } from 'store/cart/cart.selectors';
import { EDeliveryMode } from 'store/cart/cart.types';
import {
  selectCurrentShop,
  selectMenuTime,
  selectShopCurrencySymbol
} from 'store/shop/shop.selectors';
import './Header.css';

export const Header = () => {
  const currentShop = useSelector(selectCurrentShop);
  const menuTime = useSelector(selectMenuTime);
  const currencySymbol = useSelector(selectShopCurrencySymbol);
  const deliveryMode = useSelector(selectDeliveryMode);

  const dispatch = useDispatch();

  let discount = '';
  const fixedDiscount = currentShop?.store_details.store_discount_amount;
  const percentagedDiscount = currentShop?.store_details.store_discount_percent;

  if (fixedDiscount) {
    discount = Number(fixedDiscount) === 0 ? 'No Discount' : `${currencySymbol}${fixedDiscount}`;
  } else if (percentagedDiscount) {
    discount = `${percentagedDiscount}%`;
  }

  return (
    <div className='menuWrapper'>
      <div className='menuBanner'>
        <img
          src={currentShop?.store_details.store_image_url}
          alt='banner'
        />
      </div>

      <div className='deliveryMode'>
        <h2>{currentShop?.store_details.store_name}</h2>

        <div className='modeTab'>
          <Tabs
            defaultActiveKey='delivery'
            id='uncontrolled-tab-example'
            activeKey={deliveryMode}
            onSelect={(k) => {
              if (k) dispatch(toggleDeliveryMode(k as EDeliveryMode));
            }}
          >
            <Tab
              eventKey={EDeliveryMode.DELIVERY}
              title={EDeliveryMode.DELIVERY}
            >
              <div className='deliveryTime'>
                <div className='timeMode'>
                  <img
                    src={stopwatch}
                    alt='time'
                  />

                  <p>
                    {Number(
                      currentShop?.store_details.store_prep_time_in_mins
                    ) +
                      (currentShop?.store_details.store_delivery_time_in_mins
                        ? Number(
                          currentShop.store_details
                            .store_delivery_time_in_mins
                        )
                        : 0)}{' '}
                    mins
                  </p>
                </div>

                <div className='timeMode'>
                  <img
                    src={deliveryMan}
                    alt='shipping'
                  />

                  <p>
                    {currencySymbol}{' '}

                    {currentShop?.store_details.store_delivery_fee}
                  </p>
                </div>

                <div className='timeMode'>
                  <DiscountTooltip>
                    <div>
                      <img
                        src={discount1}
                        alt='discount'
                      />

                      <p>
                        {discount}

                        <span style={{ color: 'red', }}>*</span>
                      </p>
                    </div>
                  </DiscountTooltip>
                </div>
              </div>
            </Tab>

            <Tab
              eventKey={EDeliveryMode.PICKUP}
              title={EDeliveryMode.PICKUP}
            >
              <div className='deliveryTime'>
                <div className='timeMode'>
                  <img
                    src={stopwatch}
                    alt='time'
                  />

                  <p>
                    {currentShop?.store_details.store_prep_time_in_mins} mins
                  </p>
                </div>

                <div className='timeMode'>
                  <DiscountTooltip>
                    <div>
                      <img
                        src={discount1}
                        alt='discount'
                      />

                      <p>
                        {discount}

                        <span style={{ color: 'red', }}>*</span>
                      </p>
                    </div>
                  </DiscountTooltip>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>

        <div className='menuHours'>
          <p>{menuTime}</p>
        </div>
      </div>
    </div>
  );
};
