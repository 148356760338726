/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import RightArrow from 'assets/images/Right-Arrow-Download-Transparent-PNG-Image.png';
import { PhoneNumber } from 'components';
import { config } from 'config';
import { useCartSummary } from 'hooks/useCartSummary';
import { EDeliveryMode } from 'store/cart/cart.types';
import { selectCurrentShop } from 'store/shop/shop.selectors';
import { setCurrentUserAddress } from 'store/user/user.action.creator';
import {
  selectCurrentUser,
  selectSelectedAddress
} from 'store/user/user.selectors';
import { TAddressTag } from 'store/user/user.types';
import { parsedQueryString } from 'utils/parsedQueryString';
import { IPayload } from 'utils/types';
import './ProcessToPay.css';

interface IPickUpFormInputs {
  name: string;
  phone: string;
  tableNumber: string;
}
interface IOrderUrlResponse {
  message: {
    amount: number;
    amount_due: number;
    amount_paid: number;
    attempts: number;
    created_at: number;
    currency: string;
    entity: string;
    id: string;
    notes: any[];
    offer_id: null;
    receipt: string;
    status: string;
  };
}

interface IRazorpayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  org_logo: string;
  org_name: string;
  checkout_logo: string;
  custom_branding: boolean;
}

export const ProcessToPay = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    cartItems,
    deliveryMode,
    deliveryFee,
    cartSubTotal,
    cartDiscount,
    cartTax,
    cartTotal,
    currencySymbol,
  } = useCartSummary();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const address = currentUser?.address;

  const currentShop = useSelector(selectCurrentShop);
  const selectedAddress = useSelector(selectSelectedAddress);

  const [deliveryInstructions, setDeliveryInstructions] = useState('');

  const isCartEmpty = cartItems.length === 0;

  useEffect(() => {
    if (isCartEmpty) navigate(`/${location.search}`);
  }, [isCartEmpty, navigate, location.search]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handlePayment = async (
    store: typeof currentShop,
    userInfo:
      | (Partial<typeof selectedAddress> & Partial<IPickUpFormInputs>)
      | null
      | undefined
  ) => {
    if (!store || !userInfo) return;

    setIsLoading(true);

    const userName =
      EDeliveryMode.PICKUP && userInfo.tableNumber
        ? `${userInfo.name} | Table: ${userInfo.tableNumber} `
        : userInfo.name;

    const receipt = String(Math.floor(1000 + Math.random() * 9000));
    const totalAmount = cartTotal * 100;
    const data = JSON.stringify({
      call_type: 'NEW_ORDER_ID',
      amount: totalAmount,
      currency: store?.store_details.store_currency,
      receipt: receipt,
      account_id: store?.store_details.store_rp_merchant_id,
    });

    try {
      const response = await fetch(config?.orderUrl, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': config?.xApiKey,
        },
      });
      const result = (await response.json()) as IOrderUrlResponse;

      if (result?.message?.id) {
        const razorpayOptions = {
          key: config?.partnerKey,
          amount: totalAmount,
          currency: store?.store_details.store_currency,
          name: store?.store_details.store_name,
          description: '',
          image:
            'https://www.publicdomainpictures.net/pictures/30000/nahled/plain-white-background.jpg',
          order_id: result?.message?.id,
          account_id: store?.store_details.store_rp_merchant_id,
          handler: function (response: IRazorpayResponse) {
            const {
              razorpay_payment_id,
              razorpay_order_id,
              razorpay_signature,
            } = response;

            const myHeaders = new Headers();
            myHeaders.append('x-api-key', config.xApiKey);
            myHeaders.append('Content-Type', 'application/json');

            const today = new Date();
            // today = today.toISOString().split('.')[0];
            today.setMilliseconds(0);
            const formattedToday = today.toISOString().replace('.000Z', 'Z');

            const raw: IPayload = {
              call_type: 'CONFIRM_ORDER', // const variable -- hard code it
              data: {
                metadata: {
                  store_id: store?.store_details.store_id,
                  // order_type: 'Wa', // const variable -- hard code it
                  order_type: parsedQueryString.order_type ?? config.orderType,
                  payment_gateway_order_id: razorpay_order_id,
                  payment_gateway_payment_id: razorpay_payment_id,
                  payment_gateway_signature: razorpay_signature,
                  payment_gateway_receipt_number: receipt,
                  payment_gateway_account_id:
                    store?.store_details.store_rp_merchant_id,
                },

                order: {
                  items: cartItems,
                  // orderedAt: new Date(),
                  orderedAt: formattedToday,
                  currencyCode: store?.store_details.store_currency,
                  customer: {
                    name: userName ?? '',
                    phone: `${userInfo.phone}` ?? '',
                    email: '',
                  },
                  status: 'NEW_ORDER', // keep it same it will be static
                  deliveryInfo: {
                    destination: {
                      postalCode: userInfo.pinCode ?? '',
                      city: userInfo.city ?? '',
                      state: userInfo.state ?? '',
                      countryCode: store?.store_details.store_country,
                      addressLines: [
                        userInfo.address1 ?? '',
                        userInfo.address2 ?? ''
                      ],
                      location: {
                        latitude: '',
                        longitude: '',
                      },
                    },
                    lastKnownLocation: {
                      latitude: '',
                      longitude: '',
                    },
                    note: deliveryInstructions,
                  },
                  customerNote: location.state.orderNote,
                  orderTotal: {
                    subtotal: cartSubTotal,
                    discount: cartDiscount,
                    deliveryFee: deliveryFee,
                    tax: cartTax,
                    total: cartTotal,
                  },
                  customerPayments: [
                    // keep it same. it will be same as below
                    {
                      value: 0,
                      processingStatus: 'PROCESSED',
                      paymentMethod: 'CARD',
                    }
                  ],
                  fulfillmentInfo: {
                    fulfillmentMode: deliveryMode.toUpperCase(),
                  },
                },
              },
            };

            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify(raw),
              // redirect: 'follow',
            };

            setIsLoading(true);

            fetch(config.orderUrl, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                setIsLoading(false);

                navigate(`/postOrder${location.search}`, {
                  state: { serverResponse: JSON.parse(result), },
                });
              })
              .catch((error) => {
                //
              });
          },
        };

        const rzp1 = new (window as any).Razorpay(razorpayOptions);
        rzp1.open();
      }
    } catch (error) {
      //
    }
  };

  const defaultValues = {
    name: '',
    phone: '',
    tableNumber: parsedQueryString.table_number ?? '',
  };

  const {
    watch,
    reset,
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, },
  } = useForm<IPickUpFormInputs>({
    // mode: 'onTouched',
    // mode: 'onBlur', //? The blur event fires when an element has lost focus
    mode: 'onSubmit',
    defaultValues,
  });

  const onSubmit: SubmitHandler<IPickUpFormInputs> = (data) => {
    handlePayment(currentShop, data);
  };

  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Placing the order...'
        styles={{
          overlay: (base) => ({
            ...base,
            // background: 'rgba(255, 255, 255, 0.7)',
            height: '100vh',
            zIndex: 10,
          }),
          // content: base => ({
          //   ...base,
          //   marginTop: props.marginTop,
          // }),
        }}
      >
        {/* <p>Some content or children or something.</p> */}
      </LoadingOverlay>

      <div className='ProcessToPayWrapper'>
        <div className='totalOrder'>
          <p>Total</p>

          <p>
            {currencySymbol} {cartTotal}
          </p>
        </div>

        {/* TODO: Make separate component for delivery and pickup. It would have been easy if we were using local css */}

        {deliveryMode === EDeliveryMode.DELIVERY && (
          <>
            <div className='addressWrap'>
              <div className='addressHeading'>
                <h5>
                  Address<sup>*</sup>
                </h5>

                <button
                  onClick={() => {
                    navigate(`/deliveryAddress${location.search}`);
                  }}
                >
                  + Add Address
                </button>
              </div>

              {address ? (
                Object.keys(address).map((key, index) => {
                  const addressTag = key as TAddressTag;

                  return (
                    <div
                      key={key}
                      className={`ProcessToPay__addresses ${address[addressTag]?.selected && 'selected'
                        }`}
                      onClick={() => {
                        dispatch(
                          setCurrentUserAddress({
                            [addressTag]: address[addressTag],
                          })
                        );
                      }}
                    >
                      {key}

                      <br />

                      {address[addressTag]?.name}

                      <br />

                      {address[addressTag]?.phone}

                      <br />

                      {address[addressTag]?.address1}{' '}

                      {address[addressTag]?.address2} <br />

                      {address[addressTag]?.city} {address[addressTag]?.state}{' '}

                      {address[addressTag]?.country}{' '}

                      {address[addressTag]?.pinCode}

                      <img
                        src={RightArrow}
                        alt='Right arrow'
                        width={24}
                        height={24}
                      />
                    </div>
                  );
                })
              ) : (
                <div className='ProcessToPay__addresses notSelected'>
                  Add address to proceed
                </div>
              )}
            </div>

            <div className='addressWrap'>
              <div className='addressHeading'>
                <h5>Delivery Instructions</h5>
              </div>

              <textarea
                placeholder='Add delivery instructions here'
                rows={4}
                onChange={({ target, }) => setDeliveryInstructions(target.value)}
              ></textarea>
            </div>

            <div className='process__pay__bottomfixbtn'>
              <button
                disabled={!address}
                onClick={() => handlePayment(currentShop, selectedAddress)}
              >
                Proceed to pay
              </button>
            </div>
          </>
        )}

        {deliveryMode === EDeliveryMode.PICKUP && (
          <>
            <form
              id='hook-form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='ProcessToPay__addresses__pickup'>
                <div className='form-group mb-4'>
                  <label
                    htmlFor='name'
                    className='mb-3'
                  >
                    Name <span>*</span>
                  </label>

                  <input
                    type='text'
                    id='name'
                    className={`form-control  form-control-lg ${errors.name ? 'is-invalid' : ''
                      }`}
                    {...register('name', { required: true, })}
                  />
                </div>

                <div className='form-group mb-4'>
                  <label
                    htmlFor='phone'
                    className='mb-3'
                  >
                    Phone Number <span>*</span>
                  </label>

                  {/* <input
                    id='phone'
                    {...register('phone', { required: true, })}
                    type='text'
                    maxLength={10}
                    pattern='\d{10}'
                    className={`form-control ${errors.phone ? 'is-invalid' : ''
                      }`}
                  /> */}

                  <Controller
                    control={control}
                    //    defaultValue={phone}
                    name={'phone'}
                    rules={{ required: true, }}
                    render={({ field: { value, onChange, onBlur, }, }) => (
                      <>
                        <PhoneNumber
                          // inputClass={'form-control'}
                          containerClass={`form-control ${errors.phone ? 'is-invalid' : ''
                            }`}
                          inputStyle={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            backgroundColor: 'transparent',
                            border: '0px',
                            outline: 'none',
                            WebkitBoxShadow: 'none',
                            MozBoxShadow: 'none',
                            boxShadow: 'none',
                            color: 'transparent',
                            cursor: 'default',
                          }}
                          buttonStyle={{
                            backgroundColor: 'transparent',
                            border: '0px',
                            outline: 'none',
                          }}
                          value={value}
                          onChange={onChange}
                          // country={'in'}
                          country={currentShop?.store_details.store_country_code.toLocaleLowerCase()}
                          // onlyCountries={['in']}
                          countryCodeEditable={false}
                        // enableSearch
                        />
                      </>
                    )}
                  />
                </div>

                <div className='form-group mb-4'>
                  <label
                    htmlFor='tableNumber'
                    className='mb-3'
                  >
                    Table Number{' '}

                    <span
                      style={{
                        color: '#ccc',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '18px',
                        letterSpacing: '-0.08px',
                      }}
                    >
                      (optional)
                    </span>
                  </label>

                  <input
                    type='text'
                    id='tableNumber'
                    className={`form-control  form-control-lg ${errors.tableNumber ? 'is-invalid' : ''
                      }`}
                    {...register('tableNumber', { required: false, })}
                  />
                </div>
              </div>
            </form>

            <div className='process__pay__bottomfixbtn'>
              <button
                type='submit'
                form='hook-form'
              >
                Proceed to pay
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
