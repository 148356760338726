import InfoIcon from 'assets/images/infoIcon.jpg';
import { DiscountTooltip } from 'components';
import { EDeliveryMode } from 'store/cart/cart.types';

interface Props {
  deliveryMode: EDeliveryMode;
  deliveryFee: number;
  cartSubTotal: number;
  cartDiscount: number;
  cartTax: number;
  cartTotal: number;
  currencySymbol: string | null | undefined;
}

export const TotalSummary = ({
  deliveryMode,
  deliveryFee,
  cartSubTotal,
  cartDiscount,
  cartTax,
  cartTotal,
  currencySymbol,
}: Props) => {

  return (
    <div
      className='SummaryWrap'
      style={{ marginTop: 24, }}
    >
      <div className='SummaryMain'>
        <ul>
          <li>
            <p className='title'>Subtotal</p>

            <p className='rate'>
              {currencySymbol}

              {cartSubTotal}
            </p>
          </li>

          <li>
            <DiscountTooltip placement='right'>
              <div>
                <p className='title'>
                  Discount <img
                    src={InfoIcon}
                    alt='info'
                    width={10}
                  />
                </p>
              </div>
            </DiscountTooltip>

            <p className='rate discount'>
              - {currencySymbol}

              {cartDiscount}
            </p>
          </li>

          {deliveryMode === EDeliveryMode.DELIVERY && (
            <li>
              <p className='title'>Delivery Fee</p>

              <p className='rate'>
                {currencySymbol}

                {deliveryFee}
              </p>
            </li>
          )}

          <li>
            {/* <OverlayTrigger
                placement='right'
                delay={{
                  show: 250,
                  hide: 400,
                }}
                overlay={renderTaxTooltip}
              >
                <p className='title'>
                  Taxes <img
                    src={InfoIcon}
                    alt='info'
                    width={10}
                  />
                </p>
              </OverlayTrigger> */}

            <p className='title'>Taxes</p>

            <p className='rate'>
              {currencySymbol}

              {cartTax}
            </p>
          </li>

          <li className='total'>
            <p className='title'>Total</p>

            <p className='rate'>
              {currencySymbol}

              {cartTotal}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
